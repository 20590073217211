<template>
  <div class="mt-7">
    <div class="w-3/4 max-w-authCard mx-auto">
      <img src="@/assets/img/logo.svg" width="130" class="mb-2" />
    </div>
    <div class="flex w-3/4 max-w-authCard mx-auto min-h-forgotPass">
      <img :src="cardImg" class="rounded rounded-r-none" />
      <div class="w-3/4 bg-white p-6">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-10">
          Sign In
        </p>
        <InputField
          label="Username"
          :onChange="onChangeUser"
          type="text"
          class="mb-3"
          :isTextOk="$v.email.email ? null : false"
          errorText="Please enter a correct email address."
          data-testid="username"
        />
        <InputField
          label="Password"
          :onChange="onChangePass"
          type="password"
          class="mb-2"
          :submit="signIn"
          data-testid="password"
        />
        <div class="flex justify-between items-center">
          <button
            class="flex items-center focus:outline-none"
            @click="check"
            data-testid="remember-me"
          >
            <Checkbox :active="rememberMe" class="mr-1" />
            <p class="text-md text-grey-dark-1">Remember me</p>
          </button>
          <Button
            text="Forgot Password?"
            type="tertiary"
            size="medium"
            :onClick="onClickForgotPass"
            data-testid="forgot-pass"
          />
        </div>
        <div class="flex flex-col items-center mt-10">
          <Button
            text="Sign In"
            type="primary"
            size="large"
            class="w-full"
            :onClick="signIn"
            :disabled="!($v.email.required && $v.password.required)"
            data-testid="signin"
          />
        </div>
      </div>
    </div>
    <div
      class="flex w-3/4 justify-center max-w-authCard mx-auto mt-6.5 space-x-6"
    >
      <a
        href="https://www.ismworld.org/footer/terms-of-use/"
        target="_blank"
        class="font-semibold text-md text-blue-dark-1"
      >
        Terms of Service
      </a>
      <a
        href="https://www.ismworld.org/footer/terms-conditions/"
        target="_blank"
        class="font-semibold text-md text-blue-dark-1"
      >
        Terms & Conditions
      </a>
      <a
        href="https://www.ismworld.org/footer/privacy-policy/"
        target="_blank"
        class="font-semibold text-md text-blue-dark-1"
      >
        Privacy Policy
      </a>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import card from "../../../assets/img/auth/signin.png";
import Button from "../../../components/Button/Button.vue";
import InputField from "../../../components/InputField/InputField.vue";
import Checkbox from "../../../components/Checkbox/";
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

Vue.use(Vuelidate);

export default {
  components: { InputField, Button, Checkbox },
  name: "SignIn",
  data() {
    return { rememberMe: true, email: "", password: "" };
  },
  validations: { email: { required, email }, password: { required } },
  computed: {
    ...mapState({
      authData: (state) => state.auth,
    }),
    cardImg() {
      return card;
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async signIn() {
      await this.login({
        email: this.email,
        password: this.password,
      });
    },
    onClickForgotPass() {
      this.$router.push("/forgot-password");
    },
    onChangeUser: debounce(function(value) {
      this.email = value;
    }, 500),
    check() {
      this.rememberMe = !this.rememberMe;
    },
    onChangePass(value) {
      this.password = value;
    },
  },
  mounted() {
    if (this.authData.token) {
      this.$router.push("/");
    }
  },
};
</script>
